import React from 'react';
import styled from 'styled-components/macro';
import Layout from '../components/Layout';

const Wrapper = styled.div`
  padding: 100px 40px;
`;

const NotFoundPage = () => (
  <Layout>
    <Wrapper>
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Wrapper>
  </Layout>
);

export default NotFoundPage;
